import {Backdrop, CircularProgress} from "@mui/material";


const ContentLoader = () => {
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 10000, backgroundColor: "rgba(0, 0, 0, .9)" }}
            open={true}
        >
            <CircularProgress size={100} color={"secondary"}/>
        </Backdrop>
    );
};

export default ContentLoader;