import {Box, Container, styled} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Href = styled("a", {})(({theme}) => ({
        color: theme.palette.primary.contrastText,
        backgroundColor: "black",
        borderRadius: "50%"
    }),
);

const icones = [
    // {icone: <WhatsAppIcon sx={{width: 30, height: 30}}/>, link: ""},
    {icone: <InstagramIcon sx={{width: 30, height: 30}}/>, link: "https://www.instagram.com/maestrointermediacoes/"},
    {icone: <FacebookIcon sx={{width: 30, height: 30}}/>, link: "https://www.facebook.com/profile.php?id=61552781451054"},
    {icone: <LinkedInIcon sx={{width: 30, height: 30}}/>, link: "https://www.linkedin.com/company/maestro-intermedia%C3%A7%C3%B5es/"},
    // {icone: <img src={TikTokIcon} alt={""} width={30} height={30}/>, link: ""},
    // {icone: <YouTubeIcon sx={{width: 30, height: 30}}/>, link: ""},
];

const Footer = () => {
    return (
        <Box
            component={"footer"}
            sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                py: 3,
                backgroundColor: "black",
                zIndex: 1000,
            }}
        >
            <Container
                sx={{display: "flex", justifyContent: "space-around"}}
                maxWidth={"xs"}
            >
                {
                    icones.map((item, index) => (
                        <Href href={item.link} key={index} target={"_blank"} rel={"noreferrer"}>
                            <Box key={index} sx={{width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {item.icone}
                            </Box>
                        </Href>
                    ))
                }
            </Container>
        </Box>
    );
};

export default Footer;
