import {createTheme} from "@mui/material";
import {ptBR} from '@mui/material/locale';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#8b6439',
            light: '#997F37',
            dark: '#994D37',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ca9542',
            light: '#867251',
            dark: '#FFBD96',
            contrastText: '#000000'
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                'a': {
                    textDecoration: 'none',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: '#000000',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label': {
                        color: '#000000',
                        backgroundColor: '#887453',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    },
                    '& label.Mui-focused': {
                        color: '#000000',
                        backgroundColor: '#887453',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: '#887453',
                        color: '#000000',
                    },
                }
            }
        },
    }
}, ptBR);

export default theme;