import {Box} from "@mui/material";
import Footer from "components/footer";
import TopBar from "components/topbar";
import {Outlet} from "react-router-dom";
import WppIcon from "components/wpp";

const Layout = () => {
    return (
        <>
            <TopBar/>
            <Box
                component={"main"}
                sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Outlet/>
            </Box>
            <Footer/>
            <WppIcon/>
        </>
    );
};

export default Layout;
