import { Box } from '@mui/material';
import LogoMaestro from 'assets/imagens/logo-maestro.png';

type LogoProps = {
  width?: number | string,
  height?: number | string,
}

const Logo = ({width, height}: LogoProps) => {
  return (
    <Box width={width} height={height} sx={{display: "flex", p:1}}>
      <img src={LogoMaestro} alt="logo" width={"100%"} height={"100%"} />
    </Box>
  );
};

export default Logo;