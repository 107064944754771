import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Box} from "@mui/material";
import { MENSAGEM_WHATSAPP } from 'utils/constants';

const WppIcon = () => {
    


    return (
        <Box sx={{
            width: 70,
            height: 70,
            position: "fixed",
            right: 50,
            bottom: 50,
            borderRadius: "50%",
            backgroundColor: "#00c300",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.5s ease-in-out",
            "&:hover": {
                backgroundColor: "#32d951",
                width: 90,
                height: 90,
            }
        }}>
            <a href={MENSAGEM_WHATSAPP} target="_blank" rel="noreferrer">
                <WhatsAppIcon htmlColor={"white"} sx={{fontSize: 60, ml: "2px", mt: "4px"}}/>
            </a>
        </Box>
    );
}

export default WppIcon;